import jwtDefaultConfig from './jwtDefaultConfig';
import router from "@/router";
import { initialAbility } from "@/libs/acl/config";
import Vue from "vue"

export default class JwtService {
  // Will be used by this service for making API calls
  apiClient = null
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(apiClient, jwtOverrideConfig) {
    this.apiClient = apiClient
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
  }

  init(){
    return this.apiClient
      .init()
      .then(client => {
        this.axiosIns = client;
        this.axiosIns.interceptors.request.use(
          config => {
            // Get token from localStorage
            const accessToken = this.getToken()

            // If token is present add it to request's Authorization Header
            if (accessToken) {
              // eslint-disable-next-line no-param-reassign
              config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            }
            return config
          },
          error => Promise.reject(error),
        );
        // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => response,
        error => {
          // const { config, response: { status } } = error
          const { config, response } = error
          const originalRequest = config

          if (config.url === this.jwtConfig.refreshEndpoint) {
            this.removeToken();
            this.removeRefreshToken();
            localStorage.removeItem("userData");

            // Reset ability
            Vue.prototype.$ability.update(initialAbility);

            router.push({ name: 'auth-login' });
            return new Promise((resolve, reject) => {
              reject(error);
            });
          }

          // if (status === 401) {
          if (response && response.status === 401 && config.url !== this.jwtConfig.loginEndpoint) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken().then(r => {
                this.isAlreadyFetchingAccessToken = false

                // Update accessToken in localStorage
                this.setToken(r.data.accessToken)
                this.setRefreshToken(r.data.refreshToken)

                this.onAccessTokenFetched(r.data.accessToken)
              })
              .catch( e => console.log(e));
            }
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                // Make sure to assign accessToken according to your response.
                // Check: https://pixinvent.ticksy.com/ticket/2413870
                // Change Authorization header
                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(this.axiosIns(originalRequest))
              })
            })
            return retryOriginalRequest
          }

          if (response && response.status === 403) {
            router.replace({ name: "misc-not-authorized" });
          }
          return Promise.reject(error)
        },
      );
    });
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  logout(...args) {
    return this.axiosIns.delete(this.jwtConfig.logoutEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    })
  }
}
