import OpenAPIClientAxios from "openapi-client-axios";

const apiClient = new OpenAPIClientAxios({
  definition: `${window.CONFIG.apiHost}/api/1.0.0/definition`,
  withServer: {
    url: window.CONFIG.apiHost
  },
  axiosConfigDefaults: {
    baseURL: `${window.CONFIG.apiHost}/1.0.0`
  }
});

export default apiClient;
