export default {
  namespaced: true,
  state: {
    hveAudit: null
  },
  mutations: {
    SET_HVEAUDIT(state, hveAudit) {
      state.hveAudit = hveAudit;
    },
    RESET_STATE(state) {
      state.hveAudit = null;
    }
  },
  actions: {},
  getters: {
    modulesA: state => (state.hveAudit ? state.hveAudit.option_a.indicator_groups : []),
    modulesB: state => state.hveAudit?.option_b.resolution || [],
    getModuleABySlug: (state, getters) => slug => getters.modulesA.find(m => m.slug === slug)
  }
};
