const getDefaultState = () => ({
  allIndustries: null,
  allCooperatives: null,
  allFarms: null
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_ALLINDUSTRIES(state, allIndustries) {
      state.allIndustries = allIndustries;
    },
    SET_ALLCOOPERATIVES(state, allCooperatives) {
      state.allCooperatives = allCooperatives;
    },
    SET_ALLFARMS(state, allFarms) {
      state.allFarms = allFarms;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {},
  getters: {
    getFarmById: state => id => (state.allFarms ? state.allFarms.find(farm => farm.id === id) : null),
    getCooperativeById: state => id => (state.allCooperatives ? state.allCooperatives.find(cooperative => cooperative.id === id) : null),
    getIndustryById: state => id => (state.allIndustries ? state.allIndustries.find(industry => industry.id === id) : null)
  }
};
