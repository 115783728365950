import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import * as Sentry from "@sentry/vue";

import dayjs from "dayjs";
import * as fr from "dayjs/locale/fr";
import * as relativeTime from "dayjs/plugin/relativeTime";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/acl";
import "@/libs/vue-select";

// Axios Mock Adapter
// import "@/@fake-db/db";

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: window.CONFIG.sentryDSN,
    environment: window.CONFIG.sentryEnvironment
  });
}

dayjs.locale(fr);
dayjs.extend(relativeTime);

export function build(el) { // eslint-disable-line import/prefer-default-export
  // BSV Plugin Registration
  Vue.use(ToastPlugin);
  Vue.use(ModalPlugin);

  // Composition API
  Vue.use(VueCompositionAPI);

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(el);

  delete window.App; // dereference module so this function cannot be ran again
}
