import { $themeConfig } from "@themeConfig";

const getDefaultState = () => ({
  cooperatives: [],
  currentGesCulture: null,
  currentHveCulture: null,
  gesCultures: [],
  hveCultures: [],
  currentCooperative: null,
  navigation: []
});

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    periods: [],
    currentPeriod: null,
    ...getDefaultState()
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val;
    },
    SET_PERIOD(state, val) {
      state.currentPeriod = val;
    },
    SET_COOPERATIVE(state, val) {
      state.currentCooperative = val;
    },
    SET_COOPERATIVES(state, val) {
      state.cooperatives = val;
    },
    SET_SETTINGS(state, settings) {
      state.periods = settings.periods;
      state.currentPeriod = settings.default_period;
    },
    SET_HVE_CULTURE(state, val) {
      if (state.hveCultures.includes(val)) {
        state.currentHveCulture = val;
      } else {
        [state.currentHveCulture] = state.hveCultures;
      }
    },
    SET_GES_CULTURE(state, val) {
      // TODO get rid of this when we API is ready
      const culture = val === "cereal" ? "ble" : val;
      if (state.gesCultures.includes(culture)) {
        state.currentGesCulture = culture;
      } else {
        [state.currentGesCulture] = state.gesCultures;
      }
    },
    SET_HVE_CULTURES(state, val) {
      state.hveCultures = val;
    },
    SET_GES_CULTURES(state, val) {
      state.gesCultures = val;
    },
    SET_ADMIN_NAVIGATION(state) {
      state.navigation = [
        {
          title: "Dashboard",
          route: "home",
          icon: "HomeIcon",
          resource: "Auth",
          action: "read"
        },
        {
          title: "Super Admin",
          icon: "SettingsIcon",
          resource: "all",
          action: "manage",
          route: "",
          href: `${window.CONFIG.apiHost}/admin`
        },
        {
          header: "Listes"
        },
        {
          title: "Exploitations",
          icon: "ListIcon",
          resource: "all",
          action: "manage",
          route: "admin-farms-list"
        },
        {
          title: "Coopératives",
          icon: "ListIcon",
          resource: "all",
          action: "manage",
          route: "admin-coop-list"
        },
        {
          title: "IAA",
          icon: "ListIcon",
          resource: "all",
          action: "manage",
          route: "admin-iaa-list"
        }
      ];
    },
    SET_IAA_NAVIGATION(state) {
      state.navigation = [
        {
          title: "Dashboard",
          route: "iaa-home",
          icon: "HomeIcon",
          resource: "Auth",
          action: "read"
        },
        {
          header: "TABLEAU DE SYNTHÈSE",
          resource: "Cooperative",
          action: "read"
        },
        {
          title: "Démarche HVE",
          icon: "PieChartIcon",
          resource: "Cooperative",
          action: "read",
          route: "iaa-hve"
        },
        {
          title: "Nos exploitants",
          icon: "PieChartIcon",
          resource: "Cooperative",
          action: "read",
          route: "iaa-practices"
        }
      ];
    },
    SET_COOP_NAVIGATION(state) {
      state.navigation = [
        {
          title: "Dashboard",
          route: "coop-home",
          icon: "HomeIcon",
          resource: "Auth",
          action: "read"
        },
        {
          header: "TABLEAU DE SYNTHÈSE",
          resource: "Farm",
          action: "invite_farmer"
        },
        {
          title: "HVE",
          icon: "PieChartIcon",
          resource: "Farm",
          action: "invite_farmer",
          route: "coop-hve"
        },
        ...state.gesCultures.length > 0 ? [{
          title: "GES",
          icon: "PieChartIcon",
          resource: "Farm",
          action: "invite_farmer",
          route: "coop-ges"
        }] : [],
        {
          title: "Fermes",
          icon: "ListIcon",
          resource: "Farm",
          action: "invite_farmer",
          route: "coop-farms"
        }
        // {
        //   header: "Réseau social",
        //   resource: "Farm",
        //   action: "read"
        // },
        // {
        //   title: "Timeline",
        //   icon: "ActivityIcon",
        //   resource: "Auth",
        //   action: "read",
        //   route: "social-network"
        // }

      ];
    },
    SET_FARM_NAVIGATION(state, data) {
      state.navigation = [
        {
          title: "Dashboard",
          route: "farm-home",
          icon: "HomeIcon",
          resource: "Auth",
          action: "read"
        },
        {
          header: "TABLEAU DE SYNTHÈSE",
          resource: "Farm",
          action: "read"
        },
        ...state.hveCultures.length > 0 ? [{
          title: "HVE",
          icon: "PieChartIcon",
          resource: "Farm",
          action: "read",
          children: [
            {
              title: "Synthèse",
              route: { name: "farm-hve", params: { farm_id: data.farm.id } },
              resource: "Farm",
              action: "read"
            },
            ...data.option_a.indicator_groups.map(i => ({
              title: i.name,
              route: { name: `farm-hve-${i.slug}`, params: { farm_id: data.farm.id } },
              resource: "Farm",
              action: "read"
            }))
          ]
        }] : [],
        ...state.gesCultures.length > 0 ? [{
          title: "GES",
          icon: "PieChartIcon",
          resource: "Farm",
          action: "read",
          route: { name: "farm-ges", params: { farm_id: data.farm.id } }
        }] : []
        // {
        //   header: "Réseau social",
        //   resource: "Farm",
        //   action: "read"
        // },
        // {
        //   title: "Timeline",
        //   icon: "ActivityIcon",
        //   resource: "Auth",
        //   action: "read",
        //   route: "social-network"
        // }

      ];
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {}
};
