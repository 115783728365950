import { getUserData } from "@/auth/utils";
import ability from "./ability";

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || "read", route.meta.resource));

export const getHomeRouteForLoggedInUser = () => {
  const user = getUserData();

  if (!user) {
    return { name: "auth-login" };
  }

  if (user.type === "Admin") {
    return { name: "admin-home" };
  }

  if (user.type === "Industrial") {
    return { name: "iaa-home", params: { iaa_id: user.industry.id } };
  }

  if (user.type === "Technician") {
    return { name: "coop-home", params: { coop_id: user.cooperative.id } };
  }

  if (user.type === "Farmer") {
    return { name: "farm-home", params: { farm_id: user.farm.id } };
  }

  return { name: "auth-login" };
};

export const _ = undefined;
