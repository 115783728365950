import { $themeBreakpoints } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    initialized: false,
    settings: null
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state;

      if (windowWidth >= $themeBreakpoints.xl) {
        return "xl";
      }

      if (windowWidth >= $themeBreakpoints.lg) {
        return "lg";
      }

      if (windowWidth >= $themeBreakpoints.md) {
        return "md";
      }

      if (windowWidth >= $themeBreakpoints.sm) {
        return "sm";
      }

      return "xs";
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_INITIALIZED(state) {
      state.initialized = true;
    }
  },
  actions: {
    resetModulesState({ commit }) {
      commit("farm/RESET_STATE", null, { root: true });
      commit("cooperative/RESET_STATE", null, { root: true });
      commit("iaa/RESET_STATE", null, { root: true });
      commit("global/RESET_STATE", null, { root: true });
      commit("verticalMenu/RESET_STATE", null, { root: true });
    }
  }
};
