import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import farm from "./farm";
import global from "./global";
import cooperative from "./cooperative";
import iaa from "./iaa";
import admin from "./admin";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    farm,
    global,
    cooperative,
    iaa,
    admin
  },
  strict: process.env.DEV
});
