const getDefaultState = () => ({
  hveAudit: null,
  gesAudit: null,
  gesAuditStatus: "pending",
  allFarms: null
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_HVEAUDIT(state, hveAudit) {
      state.hveAudit = hveAudit;
    },
    SET_GESAUDIT(state, gesAudit) {
      state.gesAudit = gesAudit;
    },
    SET_GESAUDIT_STATUS(state, status) {
      state.gesAuditStatus = status;
    },
    SET_ALLFARMS(state, allFarms) {
      state.allFarms = allFarms;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {},
  getters: {
    getFarmById: state => id => (state.allFarms ? state.allFarms.find(farm => farm.id === id) : null)
  }
};
