export default {
  namespaced: true,
  state: {
    hveAudit: null
  },
  mutations: {
    SET_HVEAUDIT(state, hveAudit) {
      state.hveAudit = hveAudit;
    },
    RESET_STATE(state) {
      state.hveAudit = null;
    }
  },
  actions: {},
  getters: {}
};
