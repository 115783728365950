import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate, getHomeRouteForLoggedInUser } from "@/libs/acl/routeProtection";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      name: "home",
      path: "/",
      redirect: () => getHomeRouteForLoggedInUser(),
      meta: {
        action: "read",
        resource: "Auth"
      }
    },
    {
      path: "/farm/:farm_id",
      name: "farm",
      component: () => import("@/components/farm/Farm.vue"),
      redirect: { name: "farm-home" },
      meta: {
        action: "read",
        resource: "Farm"
      },
      children: [
        {
          path: "home",
          name: "farm-home",
          component: () => import("@/views/farm/Home.vue"),
          meta: {
            action: "read",
            resource: "Farm"
          }
        },
        {
          path: "hve",
          name: "farm-hve",
          component: () => import("@/views/farm/hve/Synthesis.vue"),
          meta: {
            action: "read",
            resource: "Farm"
          }
        },
        {
          path: "hve/modules/:slug",
          name: "farm-hve-modules",
          component: Vue.component("dumb-component"),
          meta: {
            action: "read",
            resource: "Farm"
          }
        },
        {
          path: "ges",
          name: "farm-ges",
          component: () => import("@/views/farm/Ges.vue"),
          meta: {
            action: "read",
            resource: "Farm"
          }
        }
      ]
    },
    {
      path: "/coop/:coop_id",
      name: "coop",
      component: () => import("@/components/cooperative/Cooperative.vue"),
      redirect: { name: "coop-home" },
      meta: {
        action: "invite_farmer",
        resource: "Farm"
      },
      children: [
        {
          path: "home",
          name: "coop-home",
          component: () => import("@/views/coop/Home.vue"),
          meta: {
            action: "invite_farmer",
            resource: "Farm"
          }
        },
        {
          path: "hve",
          name: "coop-hve",
          component: () => import("@/views/coop/Hve.vue"),
          meta: {
            action: "invite_farmer",
            resource: "Farm"
          }
        },
        {
          path: "ges",
          name: "coop-ges",
          component: () => import("@/views/coop/Ges.vue"),
          meta: {
            action: "invite_farmer",
            resource: "Farm"
          }
        },
        {
          path: "farms",
          name: "coop-farms",
          component: () => import("@/views/coop/Farms.vue"),
          meta: {
            action: "invite_farmer",
            resource: "Farm"
          }
        },
        {
          path: "farms/:id",
          name: "coop-farm-detail",
          component: () => import("@/views/coop/Farm.vue"),
          meta: {
            action: "invite_farmer",
            resource: "Farm"
          }
        }
      ]
    },
    {
      path: "/iaa/:iaa_id",
      name: "iaa",
      component: () => import("@/components/iaa/Iaa.vue"),
      redirect: { name: "iaa-home" },
      meta: {
        action: "read",
        resource: "Industry"
      },
      children: [
        {
          path: "home",
          name: "iaa-home",
          component: () => import("@/views/iaa/Home.vue"),
          meta: {
            action: "read",
            resource: "Industry"
          }
        },
        {
          path: "hve",
          name: "iaa-hve",
          component: () => import("@/views/iaa/Hve.vue"),
          meta: {
            action: "read",
            resource: "Industry"
          }
        },
        {
          path: "practices",
          name: "iaa-practices",
          component: () => import("@/views/iaa/Practices.vue"),
          meta: {
            action: "read",
            resource: "Industry"
          }
        }
      ]
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import("@/components/admin/Admin.vue"),
      redirect: { name: "admin-home" },
      meta: {
        action: "manage",
        subject: "all"
      },
      children: [
        {
          path: "home",
          name: "admin-home",
          component: () => import("@/views/admin/Home.vue"),
          meta: {
            action: "manage",
            subject: "all"
          }
        },
        {
          path: "farms",
          name: "admin-farms-list",
          component: () => import("@/views/admin/List.vue"),
          meta: {
            action: "manage",
            subject: "all"
          }
        },
        {
          path: "farms/:id",
          name: "admin-farm-details",
          component: () => import("@/views/admin/ListDetails.vue"),
          meta: {
            action: "manage",
            resource: "all"
          }
        },
        {
          path: "cooperatives",
          name: "admin-coop-list",
          component: () => import("@/views/admin/List.vue"),
          meta: {
            action: "manage",
            subject: "all"
          }
        },
        {
          path: "cooperatives/:id",
          name: "admin-coop-details",
          component: () => import("@/views/admin/ListDetails.vue"),
          meta: {
            action: "manage",
            resource: "all"
          }
        },
        {
          path: "industries",
          name: "admin-iaa-list",
          component: () => import("@/views/admin/List.vue"),
          meta: {
            action: "manage",
            subject: "all"
          }
        },
        {
          path: "industries/:id",
          name: "admin-iaa-details",
          component: () => import("@/views/admin/ListDetails.vue"),
          meta: {
            action: "manage",
            resource: "all"
          }
        }
      ]
    },
    {
      path: "/login",
      name: "auth-login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/password-recovery",
      name: "auth-forgot-password",
      component: () => import("@/views/PasswordRecovery.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/password-recovery/:token",
      name: "auth-forgot-password",
      component: () => import("@/views/PasswordRecovery.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/email-confirmation/:token",
      name: "auth-email-confirmation",
      component: () => import("@/views/EmailConfirmation.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/social-network",
      name: "social-network",
      component: () => import("@/views/social-network/Home.vue"),
      meta: {
        action: "read",
        resource: "Auth"
      }
    },
    {
      path: "/pages/not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/miscellaneous/NotAuthorized.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth"
      }
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/miscellaneous/Error.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth"
      }
    },
    {
      path: "*",
      redirect: "error-404"
    }
  ]
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser());
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");

  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
