const getDefaultState = () => ({
  iaaData: null,
  currentFilters: {
    hve: ["passing", "failing"],
    "cultures[]": ["ble", "vigne"],
    "cooperative_ids[]": []
  },
  filtersOptions: [{
    id: "hve",
    label: "Certification",
    options: [
      {
        value: "passing",
        text: "Certifiable"
      },
      {
        value: "failing",
        text: "Non certifiable"
      }
    ]
  },
  {
    id: "cultures[]",
    label: "Par culture",
    options: [
      {
        value: "ble",
        text: "Blé"
      },
      {
        value: "vigne",
        text: "Vigne"
      }
    ]
  }]
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_IAADATA(state, iaaData) {
      state.iaaData = iaaData;
    },
    SET_IAA_COOPERATIVE_FILTER(state) {
      state.filtersOptions = [
        ...state.filtersOptions,
        {
          id: "cooperative_ids[]",
          label: "Par coopérative",
          options: state.iaaData.cooperatives.map(c => ({ value: c.id, text: c.name }))
        }
      ];
      state.currentFilters["cooperative_ids[]"] = state.iaaData.cooperatives.map(c => c.id);
    },
    SET_IAA_FILTER(state, { id, value }) {
      state.currentFilters[id] = value;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {},
  getters: {
    modulesA: state => state.iaaData?.audits.hve.option_a.indicators_groups || null,
    optionB: state => state.iaaData?.audits.hve.option_b || null,
    practices: state => state.iaaData?.practices || null,
    cooperatives: state => state.iaaData?.cooperatives || null
  }
};
